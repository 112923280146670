<template>
	<v-dialog
		:value="value"
		max-width="500px"
		@keydown.esc="$emit('input', false)"
		persistent
		no-click-animation
	>
		<v-toolbar class="primary white--text">
			<span v-if="mode === 1">{{ $t("NewBillingAddress") }}</span>
			<span v-else>{{ $t("UpdateBillingAddress") }}</span><v-spacer></v-spacer>
					<v-btn icon dark @click="$emit('input', false)">
						<v-icon>close</v-icon>
					</v-btn>
		</v-toolbar>
	<v-card>

	<v-card-text>
		<v-form	v-if="metadataLoaded">
			<v-overlay :absolute="true" :value="FormLoading" :z-index="4">
				<v-progress-circular indeterminate size="64"></v-progress-circular>
			</v-overlay>
			<v-alert type="warning" v-if="errorMessage">
				{{ errorMessage }}
			</v-alert>
			<v-container fluid>
				<v-row dense>
					<v-col>
						<v-radio-group v-model="formBillingProfile.HasPartitaIVA" row
										v-validate="metadata['HasPartitaIVA'].ClientValidators"
										data-vv-scope="formBillingProfile"
										:data-vv-as="$t('formBillingProfile.HasPartitaIVA')"
										data-vv-name="HasPartitaIVA"
										key="formBillingProfile.HasPartitaIVA"
										:error-messages="errors.collect('formBillingProfile.HasPartitaIVA')">
							<v-radio :value="true" :label="$t('formBillingProfile.companyType_Company')"></v-radio>
							<v-radio :value="false" :label="$t('formBillingProfile.companyType_Personal')"></v-radio>
						</v-radio-group>
					</v-col>
				</v-row>
				<v-row dense v-if="formBillingProfile.HasPartitaIVA">
					<v-col>
						<v-text-field v-model="formBillingProfile.RagioneSociale"
									:label="$t('formBillingProfile.RagioneSociale')"
									:counter="metadata['RagioneSociale'].MaxLength"
									v-validate="metadata['RagioneSociale'].ClientValidators"
									data-vv-scope="formBillingProfile"
									:data-vv-as="$t('formBillingProfile.RagioneSociale')"
									data-vv-name="RagioneSociale"
									key="formBillingProfile.RagioneSociale"
									:error-messages="errors.collect('formBillingProfile.RagioneSociale')"></v-text-field>
					</v-col>
				</v-row>
				<v-row dense v-if="formBillingProfile.HasPartitaIVA">
					<v-col>
						<v-text-field
								v-model="formBillingProfile.PartitaIVA"
								:label="$t('formBillingProfile.PartitaIVA')"
								:counter="metadata['PartitaIVA'].MaxLength"
								v-validate="metadata['PartitaIVA'].ClientValidators"
								data-vv-scope="formBillingProfile"
								:data-vv-as="$t('formBillingProfile.PartitaIVA')"
								data-vv-name="PartitaIVA"
								key="formBillingProfile.PartitaIVA"
								:error-messages="errors.collect('formBillingProfile.PartitaIVA')"
						></v-text-field>
					</v-col>
				</v-row>
				<v-row dense v-if="!formBillingProfile.HasPartitaIVA">
						<v-col>
							<v-text-field
								v-model="formBillingProfile.Name"
								:label="$t('formBillingProfile.Name')"
								:counter="metadata['Name'].MaxLength"
								v-validate="metadata['Name'].ClientValidators"
								data-vv-scope="formBillingProfile"
								:data-vv-as="$t('formBillingProfile.Name')"
								data-vv-name="Name"
								key="formBillingProfile.Name"
								:error-messages="errors.collect('formBillingProfile.Name')"
								></v-text-field>
						</v-col>
					</v-row>
				<v-row dense v-if="!formBillingProfile.HasPartitaIVA">
					<v-col>
						<v-text-field
							v-model="formBillingProfile.Surname"
							:label="$t('formBillingProfile.Surname')"
							:counter="metadata['Surname'].MaxLength"
							v-validate="metadata['Surname'].ClientValidators"
							data-vv-scope="formBillingProfile"
							:data-vv-as="$t('formBillingProfile.Surname')"
							data-vv-name="Surname"
							key="formBillingProfile.Surname"
							:error-messages="errors.collect('formBillingProfile.Surname')"
							></v-text-field>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col>
						<CountryPicker v-model="formBillingProfile.CountryId"
								:label="$t('formBillingProfile.CountryId')"
								:counter="metadata['CountryId'].MaxLength"
								v-validate="metadata['CountryId'].ClientValidators"
								data-vv-scope="formBillingProfile"
								:data-vv-as="$t('formBillingProfile.CountryId')"
								data-vv-name="CountryId"
								key="formBillingProfile.CountryId"
								:error-messages="errors.collect('formBillingProfile.CountryId')">
						</CountryPicker>
					</v-col>
				</v-row>
				<v-row dense v-if="!formBillingProfile.HasPartitaIVA && formBillingProfile.CountryId === 1"
					><v-col>
						<v-text-field
							v-model="formBillingProfile.CodiceFiscale"
							:label="$t('formBillingProfile.CodiceFiscale')"
							:counter="metadata['CodiceFiscale'].MaxLength"
							v-validate="metadata['CodiceFiscale'].ClientValidators"
							data-vv-scope="formBillingProfile"
							:data-vv-as="$t('formBillingProfile.CodiceFiscale')"
							data-vv-name="CodiceFiscale"
							key="formBillingProfile.CodiceFiscale"
							:error-messages="errors.collect('formBillingProfile.CodiceFiscale')"
							></v-text-field>
					</v-col>
				</v-row>
				<v-row dense v-if="formBillingProfile.CountryId === 1">
					<v-col>
						<v-text-field v-model="formBillingProfile.CodiceSDI"
							:label="$t('formBillingProfile.CodiceSDI')"
							:counter="metadata['CodiceSDI'].MaxLength"
							v-validate="metadata['CodiceSDI'].ClientValidators"
							data-vv-scope="formBillingProfile"
							:data-vv-as="$t('formBillingProfile.CodiceSDI')"
							data-vv-name="CodiceSDI"
							key="formBillingProfile.CodiceSDI"
							:error-messages="errors.collect('formBillingProfile.CodiceSDI')"
						></v-text-field>
					</v-col>
				</v-row>
				<v-row dense v-if="formBillingProfile.CountryId === 1">
					<v-col>
						<ItalianCityPicker v-model="formBillingProfile.ComuneId"
							:label="$t('formBillingProfile.ComuneId')"
							:counter="metadata['ComuneId'].MaxLength"
							v-validate="metadata['ComuneId'].ClientValidators"
							data-vv-scope="formBillingProfile"
							:data-vv-as="$t('formBillingProfile.ComuneId')"
							data-vv-name="ComuneId"
							key="formBillingProfile.ComuneId"
							:error-messages="errors.collect('formBillingProfile.ComuneId')"
						></ItalianCityPicker>
					</v-col>
				</v-row>
				<v-row dense v-if="formBillingProfile.CountryId !== 1">
					<v-col>
						<v-text-field v-model="formBillingProfile.NonItalianCityName"
							:label="$t('formBillingProfile.NonItalianCityName')"
							:counter="metadata['NonItalianCityName'].MaxLength"
							v-validate=" metadata['NonItalianCityName'].ClientValidators "
							data-vv-scope="formBillingProfile"
							:data-vv-as="$t('formBillingProfile.NonItalianCityName')"
							data-vv-name="NonItalianCityName"
							key="formBillingProfile.NonItalianCityName"
						:error-messages="errors.collect('formBillingProfile.NonItalianCityName')"></v-text-field>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col>
						<v-text-field v-model="formBillingProfile.Address"
							:label="$t('formBillingProfile.Address')"
							:counter="metadata['Address'].MaxLength"
							v-validate="metadata['Address'].ClientValidators"
							data-vv-scope="formBillingProfile"
							:data-vv-as="$t('formBillingProfile.Address')"
							data-vv-name="Address"
							key="formBillingProfile.Address"
							:error-messages="errors.collect('formBillingProfile.Address')"></v-text-field>
						</v-col>
					</v-row>

				<v-row dense>
					<v-col>
						<v-text-field v-model="formBillingProfile.PostalCode"
							:label="$t('formBillingProfile.PostalCode')"
							:counter="metadata['PostalCode'].MaxLength"
							v-validate="metadata['PostalCode'].ClientValidators"
							data-vv-scope="formBillingProfile"
							:data-vv-as="$t('formBillingProfile.PostalCode')"
							data-vv-name="PostalCode"
							key="formBillingProfile.PostalCode"
							:error-messages="errors.collect('formBillingProfile.PostalCode')"></v-text-field>
					</v-col>
				</v-row>
			</v-container>
		</v-form>

	</v-card-text>
<v-card-actions>
	<v-spacer></v-spacer>
	<v-btn @click="$emit('input', false)" text primary>{{ $t("common.cancel") }}</v-btn>
	<v-btn v-if="mode === 1" color="primary" @click="AddOrSaveBillingProfile">{{ $t("common.add") }}</v-btn>
	<v-btn v-if="mode === 2" color="primary" @click="AddOrSaveBillingProfile">{{ $t("common.update") }}</v-btn>
</v-card-actions>
</v-card>
	</v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import metadata from "@/mixins/metadata";
// C: \Users\garod\OneDrive\Documenti\Visual Studio 2017\Projects\GenericCMS\sgp - qcommerce\src\components\Shared\FormControl\CountryPicker.vue
import ItalianCityPicker from "@/components/Shared/FormControl/ItalianCityPicker";
import CountryPicker from "@/components/Shared/FormControl/CountryPicker";

export default {
	name: "BillingInfoForm",
	$_veeValidate: { validator: "BillingInfo" },
	components: { CountryPicker, ItalianCityPicker },
	mixins: [metadata],
	props: {
		value: { type: Boolean, required: true },
		mode: { type: Number, required: true },
		BillingProfileId: { type: Number },
		UserId: { type: String },
	},
	data() {
		return {
			errorMessage: null,
			FormLoading: false,
			formBillingProfile: {
				HasPartitaIVA: null,
				Name: null,
				Surname: null,
				RagioneSociale: null,
				PartitaIVA: null,
				CodiceFiscale: null,
				NonItalianCityName: null,
				Address: null,
				PostalCode: null,
				ComuneId: null,
				CountryId: null,
				CodiceSDI: null
			}
		};
	},
	watch: {
		value: async function (val) {
			if (!val) this.ClearForm();
		},
		BillingProfileId: {
			immediate: true,
			handler: async function (val) {
				this.$log.debug("watch BillingProfileId");
				this.$log.debug(val);

				if (val) {
					this.itemDetails = await this.LoadDetails(val);
				}
			}
		},
	},
	created() {
		if (this.UserId) {
			this.BillingProfileService = new CrudClient("SGP/Client/BillingProfiles");
		} else {
			this.BillingProfileService = new CrudClient(
				"SGP/Client/BillingProfilesSelf"
			);
		}
	},
	async mounted() {
		await this.LoadMetadata(this.BillingProfileService);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),
		isBlank(str) {
			return (!str || /^\s*$/.test(str));
		},

		validateIfEmpty(fieldName) {
			if (this.isBlank(this.formBillingProfile[fieldName])) {
				this.errorMessage = `Il campo ${this.$t("formBillingProfile." + fieldName)} è richiesto.`;
				return false;
			}

			return true;
		},
		async AddOrSaveBillingProfile() {
			this.errorMessage = null;

			const validationResult = await this.$validator.validateAll(
				"formBillingProfile"
			);
			this.$log.debug("validation result:" + validationResult);
			if (!validationResult) {
				this.$log.info("form [formBillingProfile] not valid");
				return false;
			}
			try {
				this.FormLoading = true;
				const dto = {};

				dto.UserId = this.UserId;
				dto.HasPartitaIVA = this.formBillingProfile.HasPartitaIVA;
				dto.CountryId = this.formBillingProfile.CountryId;
				dto.Address = this.formBillingProfile.Address;
				dto.PostalCode = this.formBillingProfile.PostalCode;

				// check if Italy is selected
				if (this.formBillingProfile.CountryId === 1) {
					if (!this.validateIfEmpty("ComuneId")) return;
					dto.ComuneId = this.formBillingProfile.ComuneId;
					dto.NonItalianCityName = null;
					dto.CodiceSDI = this.formBillingProfile.CodiceSDI;
				} else {
					if (!this.validateIfEmpty("NonItalianCityName")) return;
					dto.NonItalianCityName = this.formBillingProfile.NonItalianCityName;
					dto.ComuneId = null;
					dto.CodiceSDI = null;
				}

				if (this.formBillingProfile.HasPartitaIVA) {
					if (!this.validateIfEmpty("RagioneSociale")) return;
					if (!this.validateIfEmpty("PartitaIVA")) return;
					// business
					dto.RagioneSociale = this.formBillingProfile.RagioneSociale;
					dto.PartitaIVA = this.formBillingProfile.PartitaIVA;

					dto.Name = null;
					dto.Surname = null;
					dto.CodiceFiscale = null;
				} else {
					// personal client
					if (!this.validateIfEmpty("Name")) return;
					if (!this.validateIfEmpty("Surname")) return;
					dto.Name = this.formBillingProfile.Name;
					dto.Surname = this.formBillingProfile.Surname;

					dto.RagioneSociale = null;
					dto.PartitaIVA = null;

					// CodiceFiscale required for italian personal clients
					if (this.formBillingProfile.CountryId === 1) {
						if (!this.validateIfEmpty("CodiceFiscale")) return;
						dto.CodiceFiscale = this.formBillingProfile.CodiceFiscale;
					} else {
						dto.CodiceFiscale = null;
					}
				}

				if (this.mode === 1) {
					dto.IsActive = true;
					await this.BillingProfileService.PostCustom(null, dto, null, false);
				} else {
					await this.BillingProfileService.Patch(this.BillingProfileId, dto, false);
				}
				this.snackSuccess({ Text: this.$t("common.success.updateData") });
				this.$emit("updated", this.BillingProfileId);
				this.ClearForm();
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("brand.error.cannotSaveData") });
			} finally {
				this.FormLoading = false;
			}
		},

		async LoadDetails(id) {
			try {
				this.FormLoading = true;

				const BillingProfileDetails = await this.BillingProfileService.GetCustom(id);

				this.formBillingProfile.HasPartitaIVA = BillingProfileDetails.HasPartitaIVA;
				this.formBillingProfile.CodiceSDI = BillingProfileDetails.CodiceSDI;
				this.formBillingProfile.CodiceFiscale = BillingProfileDetails.CodiceFiscale;
				this.formBillingProfile.RagioneSociale = BillingProfileDetails.RagioneSociale;
				this.formBillingProfile.PartitaIVA = BillingProfileDetails.PartitaIVA;
				this.formBillingProfile.Address = BillingProfileDetails.Address;
				this.formBillingProfile.ComuneId = BillingProfileDetails.ComuneId;
				this.formBillingProfile.PostalCode = BillingProfileDetails.PostalCode;
				this.formBillingProfile.Name = BillingProfileDetails.Name;
				this.formBillingProfile.Surname = BillingProfileDetails.Surname;
				this.formBillingProfile.CountryId = BillingProfileDetails.CountryId;
				this.formBillingProfile.NonItalianCityName = BillingProfileDetails.NonItalianCityName;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("brand.error.cannotLoadDetails") });
			} finally {
				this.FormLoading = false;
			}
		},

		ClearForm() {
			this.errorMessage = null;

			this.formBillingProfile.HasPartitaIVA = null;
			this.formBillingProfile.CodiceSDI = null;
			this.formBillingProfile.Name = null;
			this.formBillingProfile.Surname = null;
			this.formBillingProfile.RagioneSociale = null;
			this.formBillingProfile.PartitaIVA = null;
			this.formBillingProfile.CodiceFiscale = null;
			this.formBillingProfile.NonItalianCityName = null;
			this.formBillingProfile.Address = null;
			this.formBillingProfile.PostalCode = null;
			this.formBillingProfile.ComuneId = null;
			this.formBillingProfile.CountryId = null;

			this.$validator.reset();
		},
	},
};
</script>
<i18n>
{
	"it": {
		"NewBillingAddress": "Nuovo profilo di fatturazione",
		"UpdateBillingAddress": "Aggiorna il tuo profilo di fatturazione",
		"addBillingProfile_Header": "Dati di Fatturazione",
		"NotActive": "Non attivo",
		"formBillingProfile":{
			"companyType_Company": "Azienda",
			"companyType_Personal": "Persona Fisica",
			"RagioneSociale": "Ragione Sociale",
			"CodiceFiscale": "Codice Fiscale",
			"PartitaIVA": "Partita IVA",
			"CodiceSDI": "Codice SDI",
			"Address": "Indirizzo",
			"ComuneId": "Città",
			"PostalCode": "CAP",
			"Name": "Nome",
			"Surname": "Cognome",
			"CountryId": "Stato di residenza",
			"NonItalianCityName": "Nome città estera"
		}
	},
	"en": {
		"NewBillingAddress": "Add billing information",
		"UpdateBillingAddress": "Update billing information",
		"addBillingProfile_Header": "Billing Information",
		"NotActive": "Not active",
		"formBillingProfile":{
			"companyType_Company": "Business",
			"companyType_Personal": "Private",
			"RagioneSociale": "Business name",
			"CodiceFiscale": "Codice Fiscale",
			"CodiceSDI": "SDI Code",
			"PartitaIVA": "Tax Identification Number",
			"Address": "Registred address",
			"ComuneId": "City",
			"PostalCode": "Postal Code",
			"Name": "Name",
			"Surname": "Surname",
			"CountryId": "Country",
			"NonItalianCityName": "City"
		}
	}
}
</i18n>
